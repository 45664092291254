<template>
    <b-modal
        id="modal-api-youtube"
        ref="my-modal"
        title="API de YouTube"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- key -->
                <b-col cols="12">
                    <b-form-group
                        label-for="API Key"
                    >
                        <label >API Key <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="API Key"
                            rules="required"
                            >
                            <b-form-input
                                id="key"
                                v-model="key"
                                :state="errors.length > 0 ? false:null"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                     <b-form-group
                        label-for="Canal ID"
                    >
                        <label >Canal ID <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="ACanal ID"
                            rules="required"
                            >
                            <b-form-input
                                id="channelId"
                                v-model="channelId"
                                :state="errors.length > 0 ? false:null"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                     <b-form-group
                        label-for="Nombre del Canal"
                    >
                        <label >Nombre del Canal <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="Nombre del Canal"
                            rules="required"
                            >
                            <b-form-input
                                id="channel"
                                v-model="channel"
                                :state="errors.length > 0 ? false:null"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect 
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {

    },
    data () {
        return {
            id:1,
            key:'AIzaSyCV1PRfayE9ec2cyg7gMGGT1nD3RwddiX4',
            channelId:'UCNl4uVhlrmdTN0rIrJzK2XA',
            channel:'Alianza Libres Sin Violencia',
            submitted: false,
            errores:[],
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        this.actualizar();
                    }
                }
            })
        },
        cerrarModal(){

        },
        actualizar(){
            this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
            })
            this.submitted = false;
        }
    },
    mounted() {
        const api = this.mixing.opcions.find(g => g.nombre === 'api_youtube');
        
        const api_youtube = JSON.parse(api.valor);
        this.id=api.id;
        this.key=api_youtube.attributes.key;
        this.channelId=api_youtube.attributes.channelId;
        this.channel=api_youtube.attributes.channel;
    },
}
</script>